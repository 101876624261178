$Pitagon-Sans-Text: Pitagon Sans Text;

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #3ADAA6;
  border-color: #3ADAA6 !important;
  color: white;
  border-radius: 8px;
}

.mat-calendar-body-cell-content.mat-focus-indicator {
  font-family: $Pitagon-Sans-Text !important;
}

.mat-calendar-body-cell-content:hover, 
.mat-calendar-body-cell-container:hover {
  background-color: #3ADAA6;
  border-color: #3ADAA6 !important;
  color: white !important;
  border-radius: 8px;
}

.mat-calendar-header {
  padding-top: 0px !important;
}

.mat-calendar-controls {
  margin: 0px !important;
}

#mat-calendar-button-0 {
  font-family: $Pitagon-Sans-Text !important;
}

.mat-dialog-container {
  padding: 0 !important;
}
