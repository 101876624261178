$Pitagon-Sans: Pitagon Sans;
$Pitagon-Sans-Text: Pitagon Sans Text;

// Custom Calendar Grid
.cal-week-view .cal-day-headers .cal-header:first-child {
    border-left: none !important;
}

mwl-calendar-week-view > div > div.cal-time-events > div.cal-day-columns > div:nth-child(1) {
    border-left: none !important;
}

mwl-calendar-week-view > div > div.cal-time-events > div.cal-time-label-column.ng-star-inserted > div {
    border-bottom: none !important;
    height: 61px !important;
}

.cal-week-view .cal-day-headers .cal-header {
    background-color: rgba(224, 224, 224, 0.3) !important;
}

.cal-week-view .cal-day-headers .cal-header {
    background-color: rgba(236, 238, 246, 1) !important;
}

mwl-calendar-week-view > div > mwl-calendar-week-view-header > div > div.cal-header.cal-past.ng-star-inserted {
    background-color: #F5F5F5 !important;

}

mwl-calendar-week-view > div > mwl-calendar-week-view-header > div > div.cal-header.cal-future.ng-star-inserted {
    background-color: #FCFCFC !important;
}

.cal-week-view .cal-day-headers .cal-header {
    text-align: left !important;
}

.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment, .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: none !important;
}

.cal-week-view .cal-hour-odd, .cal-day-view .cal-hour-odd {
    background-color: #FCFCFC !important;
}

.cal-week-view .cal-hour:nth-child(odd), .cal-day-view .cal-hour:nth-child(odd),
.cal-week-view .cal-hour-odd, .cal-day-view .cal-hour-odd,
.cal-week-view .cal-all-day-events {
    border-bottom: 0.3px solid rgba(110, 113, 145, 0.3) !important;
}

.cal-week-view .cal-time, 
.cal-week-view .cal-header b, .cal-day-view .cal-header b,
.cal-week-view .cal-header span, .cal-day-view .cal-header span {
    font-weight: 800 !important;
    font-family: $Pitagon-Sans-Text !important;
}

mwl-calendar-week-view > div > div.cal-time-events > div.cal-time-label-column.ng-star-inserted > div.cal-hour > mwl-calendar-week-view-hour-segment > div > div {
    font-family: $Pitagon-Sans-Text !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}

.cal-week-view .cal-header b, .cal-day-view .cal-header b {
    font-size: 13px !important;
    line-height: 15px !important;
    color: rgba(68, 66, 89, 1) !important;
}

.cal-week-view .cal-header span, .cal-day-view .cal-header span {
    font-size: 20px !important;
    line-height: 30px !important;
    color: rgba(68, 66, 89, 1) !important;
    opacity: 1 !important;
}

mwl-calendar-week-view > div > div.cal-all-day-events.ng-star-inserted > div.cal-day-columns > div:nth-child(2) {
    border-left: none !important;
}

.cal-week-view .cal-hour:nth-child(odd), .cal-day-view .cal-hour:nth-child(odd),
mwl-calendar-week-view > div > div.cal-all-day-events.ng-star-inserted,
mwl-calendar-week-view > div > mwl-calendar-week-view-header > div {
    background-color: #FCFCFC !important;
}

mwl-calendar-week-view-current-time-marker > div {
    display: none !important;
}

.cal-day-columns .cal-day-column .cal-hour mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment, 
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    height: 30px !important;
}

mwl-calendar-week-view > div > div.cal-time-events,
mwl-calendar-week-view > div > div.cal-all-day-events.ng-star-inserted,
mwl-calendar-week-view > div > mwl-calendar-week-view-header > div {
    border-left: none !important;
}

mwl-calendar-week-view > div {
    border-top: none !important;
}

// Custom Event display
mwl-calendar-week-view-event {
    div {
        border-top: none !important;
        border-bottom: none !important;
        border-right: none !important;
        border-left-width: 5px !important;
    }

    mwl-calendar-event-title span {
        font-family: $Pitagon-Sans-Text !important;
        font-size: 10px !important;
        font-weight: 700 !important;
        line-height: 15px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
    }
}